const markers = [
    { coordinates: [-3.1883, 55.9533], fill: "#F53", text: "Scotland" },
    { coordinates: [-1.7278, 53.5074], fill: "#05C", text: "England" },
    { coordinates: [-8.2603, 53.3498], fill: "#0AC", text: "Ireland" },
    { coordinates: [-3.1791, 51.4816], fill: "#F53", text: "Wales" },
    { coordinates: [10.4050, 51.5200], fill: "#F53", text: "Germany" },
    { coordinates: [-3.7038, 40.4168], fill: "#05C", text: "Spain" },
    { coordinates: [2.3522, 48.8566], fill: "#0AC", text: "France" },
    { coordinates: [5.8952, 52.3702], fill: "#F53", text: "Netherlands" },
    { coordinates: [14.5058, 35.8980], fill: "#05C", text: "Malta" },
    { coordinates: [12.4964, 41.9028], fill: "#0AC", text: "Italy" },
    { coordinates: [19.0402, 47.4979], fill: "#F53", text: "Hungary" },
    { coordinates: [44.8271, 41.7151], fill: "#05C", text: "Georgia" },
    { coordinates: [16.3738, 48.2082], fill: "#F53", text: "Austria" },
    { coordinates: [21.0122, 52.2297], fill: "#05C", text: "Poland" },
    { coordinates: [24.7536, 59.4369], fill: "#0AC", text: "Estonia" },
    { coordinates: [24.9384, 60.1699], fill: "#F53", text: "Finland" },
    { coordinates: [4.3517, 50.8503], fill: "#05C", text: "Belgium" },
    { coordinates: [23.7275, 37.9838], fill: "#0AC", text: "Greece" },
    { coordinates: [6.1296, 49.8153], fill: "#F53", text: "Luxembourg" },
    { coordinates: [14.4378, 50.0755], fill: "#05C", text: "Czech Republic" },
    { coordinates: [21.8952, 42.6629], fill: "#0AC", text: "North Macedonia" },
    { coordinates: [21.0067, 52.2370], fill: "#F53", text: "Lithuania" },
    { coordinates: [18.0686, 59.3293], fill: "#05C", text: "Sweden" },
    { coordinates: [10.7522, 59.9139], fill: "#0AC", text: "Norway" },
    { coordinates: [25.2799, 54.6896], fill: "#F53", text: "Latvia" },
    { coordinates: [28.9784, 41.0082], fill: "#05C", text: "Bulgaria" },
    { coordinates: [26.1025, 44.4268], fill: "#0AC", text: "Romania" },
    { coordinates: [21.1655, 42.6977], fill: "#F53", text: "Albania" },
    { coordinates: [25.2797, 35.3279], fill: "#05C", text: "Cyprus" },
    { coordinates: [14.5086, 35.8980], fill: "#0AC", text: "San Marino" },
    { coordinates: [25.7482, 61.9241], fill: "#F53", text: "Iceland" },
    { coordinates: [16.3738, 48.2082], fill: "#05C", text: "Slovakia" },
    { coordinates: [14.5501, 47.5162], fill: "#0AC", text: "Slovenia" },
    { coordinates: [18.7061, 44.4268], fill: "#F53", text: "Croatia" },
    { coordinates: [10.4515, 51.1657], fill: "#05C", text: "Denmark" },
    { coordinates: [19.5033, 47.1625], fill: "#0AC", text: "Switzerland" },
    // Add more central coordinates for other European countries here
  ];
  
  export default markers;
  