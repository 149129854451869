import { useState } from "react";
import {  Link } from 'react-router-dom'
import Logout from "../login/logout";
import "./nav.css"


export default function AdminNavBar() {
    const [navbar, setNavbar] = useState(false);


    // Function to check if the user is authenticated
    const isAuthenticated = () => {
    // Get the token from local storage
    const token = localStorage.getItem('jwtToken');
  
    // Check if the token exists
    return token !== null;
    }

    const adminDisplay = isAuthenticated() ? 'block' : 'none';


    

    return (
<div>
<nav style={{display: adminDisplay}} className='w-full bg-green-500 shadow AdminNav'  >

            <div>
            
            <div  className="justify-between px-4 mx-auto lg:max-w-7xl md:items-center md:flex md:px-8">
            
                    <div className="flex items-center justify-between py-3 md:py-5 md:block">
                        
                        <div className="md:hidden">
                            <button
                                className="p-2 text-green-700 rounded-md outline-none focus:border-green-400 focus:border"
                                onClick={() => setNavbar(!navbar)}
                            >
                                {navbar ? (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-6 h-6"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                ) : (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-6 h-6"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M4 6h16M4 12h16M4 18h16"
                                        />
                                    </svg>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    <div
                        className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${
                            navbar ? "block" : "hidden"
                        }`}
                    >
                        <ul className="items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0">
                            <li className="text-white font-bold hover:text-blue-600">
                            <Link to='/home'>ADMIN</Link>
                            </li>
                            <li className="text-white font-bold hover:text-blue-600">
                            <Link to='/create-image'>NEW</Link>
                            </li>
                            <li className="text-white font-bold hover:text-blue-600">
                            <Link to='/edit/:id'>EDIT</Link>
                            </li>
                            <li className="text-red-600 font-bold hover:text-blue-600">
                                {Logout()}
                            </li>
                        </ul>
                       
                    </div>
                </div>
            </div>
        </nav>
        </div>
    );
}